import React from 'react'
import ReceiptUpload from '../components/ReceiptUpload'
import Footer from '../components/Footer'

const Receiptuploadp = () => {
  return (
    <div>
        <ReceiptUpload />
        <Footer />
    </div>
  )
}

export default Receiptuploadp