import React from 'react'
import About from '../components/About';
import Footer from '../components/Footer';

const Aboutusp = () => {
  return (
    <div className=' h-screen'>
        <About />
        <Footer />
        
    </div>
  )
}

export default Aboutusp