import React from 'react'
import RegistrationForm from '../components/RegistrationForm'
import Footer from '../components/Footer'

const Registrationformp = () => {
  return (
    <div>
        <RegistrationForm />
        <Footer />
    </div>
  )
}

export default Registrationformp